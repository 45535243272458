import React from "react";
import { FaAngleDoubleRight } from "@react-icons/all-files/fa/FaAngleDoubleRight";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import EmbedYouTubeVideo from "../../components/EmbedYouTubeVideo";
import Popup from "./Popup";

function WaitlistButton({ onClick }) {
  return (
    <div className="has-text-centered" style={{ padding: "1rem" }}>
      <button className="fancy" onClick={onClick}>
        Start free e-course now!
      </button>
    </div>
  );
}

const DESCRIPTION =
  "Welcome to #BALANCE Boot Camp with Kristie Lengel: A FREE 7 day kick start to a #BALANCED lifestyle for busy women looking to make sustainable changes to their health and wellness routine!";

function BalanceBootCamp() {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="balance-boot-camp">
      <Popup
        open={open}
        onSuccess={() => window.open("https://kristielengel.com/signup")}
        onCancel={() => setOpen(false)}
      />

      <Seo title="Balance Boot Camp" description={DESCRIPTION} />
      <h1 className="header-title header has-text-centered is-size-1 has-text-weight-bold">
        #BALANCE Boot Camp
      </h1>

      <div className="video-container">
        <EmbedYouTubeVideo
          url="https://youtube.com/embed/Yavqrv-wZmo"
          title={"Listen to a message from Kristie!"}
          autoplay
        />
      </div>

      <div className="intro">
        <div className="left">
          <h2 className="is-size-5 has-text-weight-bold">{DESCRIPTION}</h2>
          <ul>
            <li>
              <FaAngleDoubleRight size={24} className="icon" />{" "}
              <span style={{ fontWeight: 700 }}>Daily #BALANCE </span>
              goals that you can easily implement into your daily life!
            </li>
            <li>
              <FaAngleDoubleRight size={24} className="icon" />{" "}
              <span style={{ fontWeight: 700 }}>Step by step </span>
              approach to implementing sustainable change! No quick fixes. No
              fads. And definitely no more starting over on Monday!
            </li>
            <li>
              <FaAngleDoubleRight size={24} className="icon" />{" "}
              <span style={{ fontWeight: 700 }}>What to focus on </span>
              and what to forget about! There's SO much fitness and nutrition
              information out there, so you'll focus on what matters!{" "}
            </li>
          </ul>
          <WaitlistButton onClick={() => setOpen(true)} />
        </div>
        <div className="right">
          <StaticImage
            src={"../../../static/img/boot_camp_cut.png"}
            loading="lazy"
            alt="Kristie in the kitchen cutting fruit"
            height={400}
          />
        </div>
      </div>

      <div className="dark-bg">
        <div className="white-bg simple" style={{ textAlign: "center" }}>
          <p
            className="accented-text"
            style={{ fontSize: 24, fontWeight: 700 }}
          >
            It's supposed to be as simple as "eat less and move more"...
          </p>

          <p style={{ fontWeight: 700, fontSize: 18 }}>BUT!</p>

          <p>
            ...you have all the supplements
            <br />
            ...you have the workout equipment
            <br />
            ...you know what a healthy food choice is
          </p>

          <p
            className="accented-text"
            style={{ fontSize: 20, fontWeight: 700 }}
          >
            And you still can't stay consistent and lose the weight you want!
          </p>

          <p style={{ fontWeight: 700, fontSize: 18 }}>Well...</p>

          <p>
            What's stopping you from being successful is all of the "NOISE" out
            there! It's like total information overload, am I right?!
            <br />
            <span
              className="accented-text"
              style={{ fontSize: 20, fontWeight: 700 }}
            >
              -
            </span>
            <br />
            "Eat cabbage soup for 9 days and you'll lose 9 pounds"
            <br />
            "Don't eat after 6:00pm"
            <br />
            "HIIT is the best workout to lose fat"
            <br />
            "You have to eat ginger to decrease your waist size"
            <br />
            <span
              className="accented-text"
              style={{ fontSize: 20, fontWeight: 700 }}
            >
              -
            </span>
            <br />
          </p>

          <p>
            It's impossible to know where to start! The information is so
            overwhelming, it's paralyzing! And then what do we do?! We QUIT and
            order pizza! (Been there! 😂)
          </p>

          <p>
            So let's{" "}
            <span style={{ fontWeight: 700 }}>end this vicious cycle</span> once
            and for all! I'll be with you every step of the way! I'll help you
            turn off the "noise" in our heads and focus on what your body really
            needs! <span style={{ fontWeight: 700 }}>#BALANCE and basics!</span>
          </p>

          <p
            className="accented-text"
            style={{ fontSize: 20, fontWeight: 700 }}
          >
            This series is 100% FREE and doesn't require ANY equipment or
            supplements! Just a little bit of focus!{" "}
          </p>
        </div>
      </div>

      <div className="low-down">
        <h2>The Low Down!</h2>
        <div className="item">
          <div className="item-title">
            <h3>Opt in</h3>
          </div>
          <div className="item-description">
            When you enter your email, you will automatically be enrolled in my
            #BALANCE Boot Camp! In just a few minutes, you'll receive a
            confirmation email in your inbox!. If for some crazy reason you
            don't get the email, check your spam! If you're still having issues,
            make sure to email me at balance@kristielengel.com and I'll get to
            the bottom of it!
          </div>
        </div>

        <div className="item">
          <div className="item-title">
            <h3>#LEGGO</h3>
          </div>
          <div className="item-description">
            There's no better day than TODAY to start feeling your best, am I
            right? So you'll start right away! Every day for the next 7 days,
            you'll receive an email with your #BALANCE Boot Camp goal for the
            day!
          </div>
        </div>

        <div className="item">
          <div className="item-title">
            <h3>
              Show me
              <br />
              what ya got!
            </h3>
          </div>
          <div className="item-description">
            Be sure to tag me @kristielengel and use the hashtag
            #balancebootcamp so I can help support you on on your #BALANCE
            journey!
          </div>
        </div>

        <div className="item">
          <div className="item-title">
            <h3>REPEAT!</h3>
          </div>
          <div className="item-description">
            The habits you'll build this week will set you up for a lifetime of
            #BALANCE. Your goal will be to REPEAT, REPEAT, REPEAT! Consistency
            is the key to big results!
          </div>
        </div>

        <div className="footer-message">
          <p
            className="accented-text has-text-weight-bold"
            style={{ fontSize: 20, paddingBottom: "1rem" }}
          >
            The habits you'll build are SIMPLE and EASY so that you'll be able
            to stick to them!
          </p>
        </div>
      </div>

      <div className="dark-bg">
        <div className="white-bg bio">
          <div className="left">
            <StaticImage
              src="../../../static/img/lets_do_this.jpg"
              loading="lazy"
              alt="Kristie ecstatic in a gym setting"
              width={320}
              height={240}
            />

            <div>
              <WaitlistButton onClick={() => setOpen(true)} />
            </div>
          </div>

          <div className="right">
            <h4 className="has-text-centered is-size-3">
              Hey ladies! It's Kristie! AKA "Cheese" 😜{" "}
            </h4>
            <p>
              The entirety of my 20's was spent living a completely unbalanced
              lifestyle. I was super ambitious and put everything I had into
              being successful! Whether it was owning my own fitness studio,
              competing in natural bodybuilding, running the most successful
              franchise gym location in the country, or owning a meal prep
              restaurant (Version #2), I would push myself so hard that I would
              get to a point that I just couldn't push anymore! I'd then find
              myself on the opposite end of unhealthy, fighting depressive
              feelings and completely neglecting my health and career goals
              (Version 1). I lived a totally "black and white" life!
            </p>
            <p>
              But now, my lifestyle revolves around living in the GRAY! I preach
              #BALANCE! I scream it! Heck, I even got the word tattooed on the
              back of my neck! I've tossed extremes to the curb and give myself
              constant grace. I strive for #BALANCE mentally, emotionally and
              physically, and I'm so excited to help you do the same! No more
              yo-yo's. No more depriving and binging. No more starting over on
              Monday!
            </p>
          </div>
        </div>
      </div>

      <div className="footer-message" style={{ paddingBottom: "3rem" }}>
        <p
          className="accented-text has-text-weight-bold"
          style={{ fontSize: 28, paddingBottom: "1rem" }}
        >
          There's no better day than TODAY to start feeling your best!
        </p>
        <p style={{ fontSize: 20 }}>Start your #BALANCE journey</p>
        <p style={{ fontSize: 20 }}>
          <span style={{ fontWeight: 700 }}>FREE</span>, and let it be the LAST
          time
        </p>
        <p style={{ fontSize: 20 }}>you "start over" on Monday!</p>
        <WaitlistButton onClick={() => setOpen(true)} />
      </div>
    </div>
  );
}

const BalanceBootCampPage = () => (
  <Layout>
    <BalanceBootCamp />
  </Layout>
);

export default BalanceBootCampPage;
